import {ConfigService} from '../../config-provider';
import {TranslateService} from '@ngx-translate/core';
import {Component, Injector, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Store} from '@ngrx/store';
import {AppState, Config, selectLang, SetLang} from '../../state/common.actions';
import {BreakpointService} from '../layout/breakpoint.service';
import {map} from 'rxjs/operators';
import {Breakpoint} from './constans';

export function nullSafe(v: any, def: string = ''): string {
  return v == null ? def : v.toString();
}

/**
 * The base component for all project-shared component logic.
 */
@Component({template: ''})
export abstract class AbstractComponent implements OnDestroy {

  constructor() {

    this.translate = AbstractComponent.injector.get(TranslateService);
    this.configs = AbstractComponent.injector.get(ConfigService);
    this.cookie = AbstractComponent.injector.get(CookieService);
    this.bpService = AbstractComponent.injector.get(BreakpointService);

    this.languageList = this.translate.getLangs();
    this.currentLang$ = this.store.select((s) => selectLang(s.commons));
    this.view = this.bpService.currentView$;
  }

  static injector: Injector;

  protected translate: TranslateService;
  protected cookie: CookieService;
  protected configs: ConfigService;
  protected bpService: BreakpointService;

  private subscriptions: Subscription[] = [];
  readonly languageList: Array<string>;
  public currentLang$: Observable<string>;
  public view: Observable<string>;

  /**
   * We should release all the resources
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Adds a new resource to the automatic resource release.
   * @param s A subscription
   */
  protected add(s: Subscription): Subscription {
    this.subscriptions.push(s);
    return s;
  }

  trans(key: string): Observable<string> {
    return this.translate.get(key);
  }

  get store(): Store<{ commons: AppState }> {
    return this.configs.store;
  }

  get cfg(): Observable<Config> {
    return this.configs.config;
  }

  public changeLang(lang: string): void {
    this.store.dispatch(SetLang({lang: lang}));
  }

  public isMobile(): Observable<boolean> {
    return this.view.pipe(map((v) => v === Breakpoint.mobile));
  }

  public isDesktop(): Observable<boolean> {
    return this.view.pipe(map((v) => v === Breakpoint.desktop));
  }
}
