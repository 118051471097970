// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-up {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  z-index: -1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: 0.25s all ease;
  background-color: #00abb8;
  border-radius: 50%;
  box-shadow: none;
}
.btn-up.visible {
  transition: 0.25s opacity ease;
  z-index: 100;
  opacity: 1;
}
.btn-up svg-icon {
  color: #ececec;
}
.btn-up svg-icon svg {
  height: 20px;
  width: 20px;
  transform: translateY(2px);
}
.btn-up:hover {
  background-color: #30dbe8;
  box-shadow: 0 2px 5px 5px #e4e4e4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
