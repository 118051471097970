import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SliderComponent} from './common/slider/slider.component';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './common/button/button.component';
import {RouterModule} from '@angular/router';
import {StickyTableWrapperComponent} from './common/stycky-table-wrapper/sticky-table-wrapper.component';
import {BreadcrumbsComponent} from './navigation/breadcrumbs/breadcrumbs.component';
import {ButtonUpComponent} from './common/button-up/button-up.component';
import {MobileTableComponent} from './common/mobile-table/mobile-table.component';
import {BreakpointComponent} from './layout/breakpoint/breakpoint.component';
import {LogoComponent} from './layout/header/logo/logo.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {MenuComponent} from './layout/header/menu/menu.component';
import {ToggleComponent} from './layout/header/toogle/toggle.component';
import {LanguagesComponent} from './layout/languages/languages.component';

@NgModule({
  declarations: [
    BreakpointComponent,
    SliderComponent,
    ButtonComponent,
    StickyTableWrapperComponent,
    BreadcrumbsComponent,
    ButtonUpComponent,
    MobileTableComponent,
    LogoComponent,
    MenuComponent,
    ToggleComponent,
    LanguagesComponent,
  ],
  imports: [TranslateModule, CommonModule, RouterModule, AngularSvgIconModule],
  exports: [
    AngularSvgIconModule,
    BreakpointComponent,
    SliderComponent,
    ButtonComponent,
    StickyTableWrapperComponent,
    BreadcrumbsComponent,
    ButtonUpComponent,
    MobileTableComponent,
    LogoComponent,
    MenuComponent,
    ToggleComponent,
    LanguagesComponent,
  ],
  providers: [],
})
export class SharedModule {
}
