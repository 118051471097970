import {AfterContentInit, ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {BreakpointService} from '../breakpoint.service';

@Component({
  selector: 'dgc-breakpoint',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakpointComponent implements AfterContentInit {
  constructor(private bpService: BreakpointService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.bpService.width = event.target.innerWidth;
  }

  ngAfterContentInit(): void {
    this.bpService.width = window.innerWidth;
  }

}
