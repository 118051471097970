// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .cnt {
  flex-direction: row;
}

.header-desktop {
  position: relative;
  display: flex;
  width: 100%;
  height: 100px;
  background-color: #ececec;
  box-shadow: none;
  transition: 0.25s all ease;
}
.header-desktop.sticky {
  transition: 0.25s all ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 5px 1px #e4e4e4;
}

.header-mobile {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 20;
  grid-template-areas: "header" "navigation";
  grid-template-rows: 50px minmax(0, calc(100vh - 50px));
}
.header-mobile .inner {
  grid-area: header;
  position: relative;
  display: flex;
  pointer-events: auto;
  background-color: #ececec;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  box-shadow: 0 0 5px 1px #e4e4e4;
}
.header-mobile .nav-cnt {
  grid-area: navigation;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 0;
  width: 100%;
  transition: 0.33s all ease;
  background-color: #ececec;
  overflow: hidden;
  pointer-events: auto;
  z-index: 1000;
}
.header-mobile .nav-cnt.active {
  box-sizing: border-box;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  transition: 0.33s all ease;
  background-color: #ececec;
}
.header-mobile .logo {
  margin-left: 10px;
  margin-top: auto;
}
.header-mobile ::ng-deep div.langs {
  margin: 0 auto 0;
  height: 36px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
