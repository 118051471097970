// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  margin-left: auto;
  width: 50px;
  height: 50px;
}

.toggle {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-left: auto;
}
.toggle .brick {
  background-color: #30dbe8;
  width: 100%;
  height: 2px;
  margin-top: auto;
  transition: 0.33s all ease;
}
.toggle.active .brick {
  transition: 0.33s all ease;
}
.toggle.active .brick:nth-child(2) {
  display: none;
}
.toggle.active .brick:first-child {
  transform: rotate(45deg) translateY(10px);
}
.toggle.active .brick:last-child {
  transform: rotate(-45deg) translateY(-10px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
