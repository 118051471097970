import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import Swiper from 'swiper';

@Component({
  selector: 'dgc-mobile-table',
  templateUrl: './mobile-table.component.html',
  styleUrls: ['./mobile-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileTableComponent implements AfterViewInit {
  @Input() id: string = 'default';
  @ViewChild('wrapper', {static: false}) wrapper: ElementRef;
  private swiper: Swiper;

  ngAfterViewInit() {
    this.wrapper.nativeElement
      .querySelector('.table')
      .setAttribute('style', `width: ${this.wrapper.nativeElement.clientWidth * 3}px`);

  }


}
