// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1 0;
  width: 100%;
  height: 100%;
}

.header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: auto 40px auto auto;
}
.header-menu li {
  list-style: none;
  margin-left: 2rem;
}
.header-menu li a {
  font-size: 1rem;
  color: #00abb8;
  transition: 0.33s all ease-in-out;
}
.header-menu li a:hover {
  color: #30dbe8;
  transition: 0.33s all ease-in-out;
}
.header-menu li a.active {
  text-decoration: none;
  color: #30dbe8;
}

.header-menu.mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 50px 0;
}
.header-menu.mobile li {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 48px;
}
.header-menu.mobile li a {
  display: block;
  width: 100%;
  text-align: center;
  color: #00abb8;
  transition: 0.33s all ease-in-out;
}
.header-menu.mobile li a:hover {
  color: #30dbe8;
  transition: 0.33s all ease-in-out;
}
.header-menu.mobile li a.active {
  text-decoration: none;
  color: #30dbe8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
