import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef} from '@angular/core';
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

@Component({
  selector: 'dgc-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @Input() id: string = 'default';
  @Input() slides: any[];
  @Input() parallax: boolean = false;
  @Input() slideTemplate: TemplateRef<HTMLElement>;
  @Input() parallaxBackgroundPath: string = '';
  @Input() autoplay: boolean = false;
  private swiper: Swiper;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.initSwiper();
    this.cdr.detectChanges();
  }

  initSwiper() {
    this.swiper = new Swiper(`.swiper-container-${this.id}`, {
      modules: [Navigation],
      autoplay: this.autoplay,
      speed: 600,
      parallax: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}
