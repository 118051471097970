import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AppErrorHandler} from './shared/errors/error-handler';

import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';

import {ConfigService} from './config-provider';
import {SharedModule} from './shared/shared.module';
import {TranslateAppParser} from './shared/common/translate.parser';
import {CookieService} from 'ngx-cookie-service';
import {BreakpointService} from './shared/layout/breakpoint.service';
import {FooterComponent} from './shared/layout/footer/footer.component';
import {HeaderComponent} from './shared/layout/header/header.component';
import {AbstractComponent} from './shared/common/abstract.component';
import {StatesModule} from './state/states.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CommonEffects} from './state/common.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AngularSvgIconModule} from 'angular-svg-icon';

// Locales


export function configFactory(cfg: ConfigService): Function {
  return () => cfg.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function createTranslateParser() {
  return new TranslateAppParser();
}

/**
 * Main Module
 */
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        parser: {provide: TranslateParser, useFactory: (createTranslateParser)}
      }
    ),
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot({}),
    StatesModule,
    EffectsModule.forRoot([CommonEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    ConfigService,
    BreakpointService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'ru'}, AppErrorHandler,
    {provide: ErrorHandler, useClass: AppErrorHandler}, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    /**
     * It looks strange, but it's the only way to make it digest the not-by-constructor injection and avoid
     * even weirder cyclic dependencies.
     */
    AbstractComponent.injector = injector;
  }
}
