import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

enum Type {
  button = 'button',
  submit = 'submit',
  link = 'link'
}

@Component({
  selector: 'dgc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() type: string = Type.button;
  @Input() linkStyle: boolean = false;
  @Input() buttonStyle: boolean = true;
  @Input() full: boolean = false;
  @Input() size: string = '';
  @Input() disabled: boolean = false;
  @Input() to: any[] = ['#'];
  @Output() onClick = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.linkStyle) {
      this.buttonStyle = false;
    }

    if (this.buttonStyle) {
      this.linkStyle = false;
    }

  }

  emitClick(evt) {
    this.onClick.emit(evt);
  }
}
