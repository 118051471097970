import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, Renderer2} from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractComponent} from '../../common/abstract.component';
import {DOCUMENT} from '@angular/common';
import {Breakpoint} from '../../common/constans';

@Component({
  selector: 'dgc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends AbstractComponent implements AfterViewInit {
  isManuActive: boolean = false;
  isStickyHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private render: Renderer2, @Inject(DOCUMENT) private document: Document) {
    super();
  }

  ngAfterViewInit(): void {
    this.view.subscribe({
      next: (breakpoint) => {
        if (breakpoint !== Breakpoint.desktop) {
          return;
        }
        this.watchScroll();
      },
    });
  }

  watchScroll() {
    const body = this.document.body;
    const scrollEvent$ = fromEvent(window, `scroll`).pipe(map((evt) => evt));
    const scrollHandler = () => {
      this.isStickyHeader$.next(body && body.getBoundingClientRect().top <= -100);
      if (this.isStickyHeader$.getValue()) {
        this.render.setStyle(body, 'padding-top', '100px');
      } else {
        this.render.removeStyle(body, 'padding-top');
      }
    };

    scrollEvent$.subscribe({
      next: (evt) => scrollHandler(),
    });
  }

  toggleScrollOnBody() {
    const body = this.document.body;
    this.isManuActive ? (body.style.overflow = 'hidden') : (body.style.overflow = 'auto');
  }
}
