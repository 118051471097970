import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {filter, Observable, takeUntil} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {AbstractDestroyableComponent} from '../../../common/abstract-destroyable.component';

@Component({
  selector: 'logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent extends AbstractDestroyableComponent {
  @Input() isMobile: boolean = true;

  routerLinkPath$: Observable<string>;

  constructor(router: Router) {
    super();
    this.routerLinkPath$ = router.events.pipe(
      takeUntil(this.destroyed$),
      filter((evt): evt is NavigationEnd => evt instanceof NavigationEnd),
      map(evt => evt.urlAfterRedirects === '/welcome' ? '' : 'welcome')
    );
  }

  get logoSrc(): string {
    return `assets/images/logo/${this.isMobile ? 'mobile' : 'desktop'}.svg`;
  }
}
