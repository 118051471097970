import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'languages',
  templateUrl: 'languages.component.html',
  styleUrls: ['languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent {
  @Input() shortMode: boolean = false;
  @Input() languages: string[] = [];
  @Input() current: string = '';

  @Output() onLanguageClick: EventEmitter<string> = new EventEmitter<string>();
}
