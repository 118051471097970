// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1100px) {
  .cnt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1100px;
    margin: 0 auto;
  }
}

.wrapper {
  width: 100%;
}

:host .cnt {
  flex-direction: row;
  align-items: center;
}

.footer {
  background-color: #ececec;
  height: 50px;
  width: 100%;
  flex-shrink: 0;
}
.footer .copyright {
  margin-right: auto;
}
.footer .privacy {
  margin-top: 1rem;
  width: 60%;
  font-size: 0.75rem;
}
.footer.mobile {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 2rem;
}
.footer.mobile ::ng-deep .langs {
  margin-left: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
