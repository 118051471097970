// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100%;
}

.langs {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}
.langs dgc-button {
  margin-left: 10px;
}

.langs {
  display: flex;
  justify-content: center;
}
.langs dgc-button {
  margin-left: 10px;
  margin-right: 10px;
}

.lang-btn {
  width: auto;
  padding: 4px 8px;
  color: #00abb8;
  transition: 0.33s all ease-in-out;
  cursor: pointer;
  opacity: 0.8;
}
.lang-btn:not(:first-child) {
  margin-left: 8px;
}

.lang-btn.lang-btn-short {
  border-radius: 4px;
  border: 1px solid transparent;
}

.lang-btn.lang-btn-long {
  text-decoration: underline;
}

.lang-btn.lang-btn-short.lang-active {
  border-color: #30dbe8;
}

.lang-btn.lang-btn-long.lang-active {
  text-decoration: none;
}

.lang-btn.lang-active {
  color: #30dbe8;
  transition: 0.33s all ease-in-out;
  cursor: default;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
