import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from './config-provider';
import {LoadLang} from './state/common.actions';

/**
 *  Container Page
 */
@Component({
  selector: 'dgc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(private route: ActivatedRoute, private cfg: ConfigService) {
    this.cfg.store.dispatch(LoadLang());
  }

  ngAfterViewInit(): void {
    console.log(navigator.language);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
    });
  }
}
