// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn,
.button,
a.btn {
  background-image: none;
  box-shadow: none;
  height: 48px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.05rem;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.33s all ease-in-out;
  color: #ececec;
}
.btn.large,
.button.large,
a.btn.large {
  height: 60px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.2rem;
}
.btn.small,
.button.small,
a.btn.small {
  height: 44px;
}
.btn.full,
.button.full,
a.btn.full {
  width: 100%;
}
.btn:disabled,
.button:disabled,
a.btn:disabled {
  background-color: #888888;
  color: #ececec;
}
.btn:hover:not(:disabled),
.button:hover:not(:disabled),
a.btn:hover:not(:disabled) {
  transition: 0.33s background-image ease-in-out, 0s box-shadow ease-in-out, 0.33s color ease-in-out;
  background-color: #30dbe8;
  box-shadow: 0 2px 5px 5px #e4e4e4;
  color: #ececec;
}

.link {
  cursor: pointer;
  font-size: 1rem;
  transition: 0.33s border-bottom-color ease-in-out;
}
.link:hover:not(:disabled) {
  border-bottom-color: transparent;
  transition: 0.11s border-bottom-color ease-in-out;
}
.link:disabled {
  opacity: 0.7;
}

a.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
