export enum Breakpoint {
  desktop = 'DESKTOP',
  mobile = 'MOBILE'
}

export enum Language {
  english = 'en',
  russian = 'ru'
}

export const LANGUAGES = [Language.english, Language.russian];
