import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState, Config, selectCfg, SetConfig} from './state/common.actions';

/**
 * TODO: migrate it to dgc.lib.ts
 */
@Injectable({providedIn: 'root'})
export class ConfigService {

  private _cfg: Config;

  constructor(private http: HttpClient, public appStore: Store<{ commons: AppState }>) {
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load(): Observable<any> {

    this._cfg = null;
    return this.http.get<any>('/config')
      .pipe(
        map(data => {
          this._cfg = data.props;
          this.store.dispatch(SetConfig(this._cfg));
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  get config(): Observable<Config> {
    return this.store.select(s => selectCfg(s.commons));
  }

  get store(): Store<{ commons: AppState }> {
    return this.appStore;
  }

}
