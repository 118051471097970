import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'dgc-sticky-table',
  templateUrl: './sticky-table-wrapper.component.html',
  styleUrls: ['./sticky-table-wrapper.component.scss']
})
export class StickyTableWrapperComponent implements OnInit {
  @Input() cssClasses: string[];
  @Input() headerRef: HTMLElement;
  @Input() tableRef: HTMLElement;

  constructor(private render: Renderer2) {
  }


  ngOnInit(): void {
    this.watchScroll();
  }

  watchScroll() {
    const scrollEvent$ = fromEvent(window, `scroll`).pipe(
      map((evt) => evt)
    );

    scrollEvent$.subscribe(() => {
      if (this.tableRef.getBoundingClientRect().top <= 0 &&
        this.tableRef.getBoundingClientRect().bottom >= this.headerRef.getBoundingClientRect().height) {
        this.setClasses('addClass');
        this.render.setStyle(
          this.tableRef,
          'padding-top',
          `${this.headerRef.getBoundingClientRect().height}px`
        );
      } else {
        this.setClasses('removeClass');
        this.render.removeStyle(this.tableRef, 'padding-top');
      }
    });
  }

  setClasses(method: string) {
    if (this.cssClasses.length === 1) {
      this.render[method](this.headerRef, this.cssClasses[0]);
    } else {
      this.cssClasses.forEach(c => {
        this.render[method](this.headerRef, c);
      });
    }
  }


}
