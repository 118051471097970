// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-top: 15px;
}

.logo {
  margin-top: auto;
  margin-bottom: auto;
}
.logo a {
  cursor: default;
}
.logo svg-icon svg {
  height: 70px;
  width: 133px;
}

.logo.mobile {
  margin-left: 10px;
  margin-top: auto;
}
.logo.mobile svg-icon svg {
  height: 40px;
  width: 76px;
  transform: translateY(-10px);
}

.logo.cursor-pointer a {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
