import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractComponent} from '../../common/abstract.component';

@Component({
  selector: 'dgc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends AbstractComponent {
  paths: string[];

  constructor(private router: Router) {
    super();
    router.events.subscribe((url: any) => {
      if (url.urlAfterRedirects) {
        this.paths = url.urlAfterRedirects.split('/');
      }
    });
  }

}
