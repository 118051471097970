import {NavigationService} from '../../../navigation/navigation.service';

export interface MenuItem {
  id: string;
  name: string;
  path: string[];
  fragment?: string;
}

export type MenuItems = MenuItem[];

export const menuItems: MenuItems = [
  {id: 'welcome', path: [NavigationService.welcomeUrl], name: 'Menu.about', fragment: 'about'},
  {id: 'platform', path: [NavigationService.platformUrl], name: 'Menu.platform'},
  {id: 'articles', path: [NavigationService.articlesUrl], name: 'Menu.articles'},
  {id: 'portfolio', path: [NavigationService.portfolioUrl], name: 'Menu.portfolio'},
];
