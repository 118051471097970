import {TranslateDefaultParser} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

/**
 * A special parser for multiline translations.
 *
 * Please use the array values for multiline keys, i.e.:
 *
 * "key": [
 * "Fist Line",
 * "Second Line",
 * "Third Line"].
 *
 * The resulted line will be: "First Line Second Line Third Line".
 */
@Injectable()
export class TranslateAppParser extends TranslateDefaultParser {

  getValue(target: any, key: string): any {
    target = super.getValue(target, key);
    if (target instanceof Array) {
      target = target.join(' ');
    }
    return target;
  }
}
