import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Breakpoint} from '../common/constans';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BreakpointService {
  private view: BehaviorSubject<string> = new BehaviorSubject<string>(Breakpoint.mobile);
  private windowWidth: number;

  get currentView$(): Observable<string> {
    return this.view.pipe(map(v => v));
  }

  set width(w: number) {
    this.windowWidth = w;
    this.calcView();
  }

  private calcView(): void {
    this.view.next((this.windowWidth >= 1100) ? Breakpoint.desktop : Breakpoint.mobile);
  }
}
