import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import * as commons from './common.actions';

import {EffectsModule} from '@ngrx/effects';
import {CommonEffects} from './common.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(commons.COMMON_REDUCERS_KEY, commons.reducers),
    EffectsModule.forFeature([CommonEffects])
  ],
})
export class StatesModule {
}
