import {AfterViewInit, Component} from '@angular/core';
import {fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'dgc-button-up',
  templateUrl: './button-up.component.html',
  styleUrls: ['./button-up.component.scss']
})
export class ButtonUpComponent implements AfterViewInit {
  shouldShowButton = false;
  windowHeight: number;

  constructor(private router: Router) {
  }

  ngAfterViewInit(): void {
    const el = document.querySelector('body');
    const scrollEvent$ = fromEvent(window, 'scroll').pipe(map(e => e));

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.windowHeight = window.innerHeight;
      }
    });

    scrollEvent$.subscribe(() => {
      this.shouldShowButton = (this.windowHeight + el.getBoundingClientRect().top) <= 0;
    });
  }

  scrollTop() {
    const el = document.querySelector('body');
    el.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
