import {Action, createAction, createReducer, on, props} from '@ngrx/store';

export interface Config {
  buildnumber: string;
  version: string;
  production: boolean;
  banner_id: string;
  profiles: string[];
  self_host: string;
  websocket_host: string;
}

// 1. State
export interface AppState {
  lang?: string;
  config?: Config;
}

export const initialState: AppState = {
  lang: 'en'
};

// 2. Actions
export const LoadLang = createAction('[Commons] Load Lang');
export const UpdateLang = createAction('[Commons] Update Lang', props<{ lang: string }>());
export const SetLang = createAction('[Commons] Set Lang', props<{ lang: string }>());

export const SetConfig = createAction('[Commons] Set Config', props<Config>());

// 3. Reducers
export const COMMON_REDUCERS_KEY = 'commons';

const commonReducers = createReducer(
  initialState,
  on(UpdateLang, (state, {lang}) => ({...state, lang: lang})),
  on(SetConfig, (state, cfg) => ({...state, config: cfg}))
);

export function reducers(state: AppState | undefined, action: Action) {
  return commonReducers(state, action);
}

export const selectLang = (state: AppState) => state.lang;
export const selectCfg = (state: AppState) => state.config;

