import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

/**
 *  Defined Routes
 */
const routes: Routes = [
  {
    path: 'articles',
    loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesPageModule),
  },
  {
    path: 'articles/:key',
    loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesPageModule),
  },
  {
    path: 'about', loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule)
  },
  {
    path: 'platform',
    loadChildren: () => import('./pages/platform/platform.module').then((m) => m.PlatformPageModule),
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./pages/portfolio/portfolio.module').then((m) => m.PortfolioModule),
  },
  {
    path: 'documents/:key',
    loadChildren: () => import('./pages/textpages/text-page.module').then((m) => m.TextPageModule),
  },
  {
    path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule)
  },
  {
    path: '**', redirectTo: '/welcome', pathMatch: 'full'
  },
];

/**
 * AppRoutingModule
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
