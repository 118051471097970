import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'toggle',
  templateUrl: 'toggle.component.html',
  styleUrls: ['toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent {
  @Input() isActive: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
