import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppState, LoadLang, SetLang, UpdateLang} from './common.actions';
import {Store} from '@ngrx/store';
import {CookieService} from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';
import {Language, LANGUAGES} from '../shared/common/constans';
import {nullSafe} from '../shared/common/abstract.component';
import {forkJoin, mergeMap, of} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class CommonEffects {

  load$ = createEffect(() => this.actions$.pipe(
    ofType(LoadLang),
    map(() => {

      this.translate.addLangs(LANGUAGES);
      this.translate.setDefaultLang(Language.russian);

      const langs: string[] = this.translate.getLangs();
      let defLang: string = this.translate.getDefaultLang();
      const browser = this.translate.getBrowserLang();

      if (langs.indexOf(browser) >= 0) {
        defLang = browser;
      }
      // default lang = 'ru';
      const lang: string = this.cookie.check('applocales') ? this.cookie.get('applocales') : defLang;
      return SetLang({lang: lang});
    })
  ));

  set$ = createEffect(() => this.actions$.pipe(
    ofType(SetLang),
    mergeMap(a => forkJoin([this.translate.use(a.lang), of(a.lang)]).pipe(
      map(([nonce, lang]) => {
        this.cookie.set('applocales', nullSafe(lang, 'en'));
        return UpdateLang({lang: nullSafe(lang)});
      })
    ))
  ));

  constructor(private actions$: Actions, private store: Store<{ commons: AppState }>,
              private cookie: CookieService,
              private translate: TranslateService) {
  }

}
