import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class NavigationService {
  public static readonly welcomeUrl = 'welcome';
  public static readonly platformUrl = 'platform';
  public static readonly articlesUrl = 'articles';
  public static readonly portfolioUrl = 'portfolio';
  public static readonly aboutUrl = 'about';

}
