import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {MenuItem, MenuItems, menuItems} from './menu-items';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractDestroyableComponent} from '../../../common/abstract-destroyable.component';

@Component({
  selector: 'main-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends AbstractDestroyableComponent implements OnInit {
  @Input() isMobile: boolean = true;
  @Output() onMenuClick: EventEmitter<void> = new EventEmitter<void>();
  currentItem: string;

  menuItems: MenuItems = menuItems;

  constructor(private router: Router, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((evt): evt is NavigationEnd => evt instanceof NavigationEnd),
        map((evt) => evt.urlAfterRedirects.split('/').filter((v) => !!v))
      )
      .subscribe({
        next: ([url]) => {
          this.currentItem = this.menuItems.find((item) => item.id === url)?.id ?? this.menuItems[0].id;
          this.cdr.detectChanges();
        },
      });
  }

  trackByFn(_: number, item: MenuItem) {
    return item.id;
  }

  onClick(id: string) {
    this.currentItem = id;
    this.onMenuClick.emit();
  }
}
